import { Card } from "primereact/card";

interface props {
    entity: string;
}


const ErrorLoadingData: React.FC<props> = ({entity}) => {
    return (
        <>
            <div className="max-w-4xl mx-auto my-4 px-2 sm:px-4">
                <Card className="shadow-lg border-round-lg p-4 text-red-500 bg-red-100">
                    <h2 className="text-lg font-semibold">Error</h2>
                    <p>There was an error loading the {entity}. Please try again later.</p>
                </Card>
            </div>
        </>
    )
}

export default ErrorLoadingData;