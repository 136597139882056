import { User } from "@utils/interfaces/User";
import { API } from "./API_INSTANCE"

export const getUser = (userToken: string|null) => {
    return API.get('/me', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res)
        .catch(err => { throw err })
}

export const fetchUsers = async (): Promise<User[]> => {
    const response = await API.get<User[]>('/users.json');
    return response.data;
};
