// VariantAttributes.tsx
import React from 'react';
import { Divider } from 'primereact/divider';
import CountryFlag from '@components/Global/CountryFlag';

interface VariantAttributesProps {
    variantAttributes: any[];
}

const formatCurrency = (amount: number, currencyCode: string): string => {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};

const VariantAttributes: React.FC<VariantAttributesProps> = ({ variantAttributes }) => {
    return (
        <div>
            <Divider />
            <h4 className="text-lg md:text-xl font-medium text-gray-700 mb-3">Prix :</h4>

            <div className="ml-2 md:ml-4 space-y-4">
                {variantAttributes.map((attr: any) => (
                    <div key={attr.id} className="bg-gray-50 p-3 rounded-lg shadow-sm">
                        {attr.variantPrice.length > 0 && (
                            <ul className="list-none space-y-2">
                                {attr.variantPrice.map((price: any) => (
                                    <li key={price.id} className="flex items-center gap-2 text-sm md:text-base">
                                        <CountryFlag countryCode={price.country.code} size={18} />
                                        <span className="text-gray-700">{price.country.name}</span>
                                        <span className="ml-auto px-3 py-1 rounded-full bg-green-100 text-green-800 font-medium shadow-sm">
                                            {formatCurrency(price.price, price.country.currency)}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VariantAttributes;
