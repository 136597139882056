import { ColumnDef } from "@tanstack/react-table";
import { Image } from "primereact/image";
import { apiUrlbase, bannerImgUrl, categoryImgUrl } from "@helpers/Helpers";
import { Button } from "primereact/button";
import TranslationCell from "./Modals/TranslationCell";
import { Link } from "react-router-dom";

let imageNotFound = require("@resources/imageNotFound.png");

export const columns: ColumnDef<any>[] = [
    {
        header: "ID",
        accessorKey: "id",
        cell: ({ row }) => <span className="text-sm">{row.original.id}</span>,
    },
    {
        header: "Image",
        accessorKey: "image",
        cell: ({ row }) =>
            row.original.image ? (
                <Image src={`${apiUrlbase}/${row.original.image}`} alt="Category" width="100" preview downloadable={true} />
            ) : (
                <Image src={imageNotFound} alt="Image Not Found" width="100" />
            ),
    },
    {
        header: "Banner",
        accessorKey: "banner",
        cell: ({ row }) =>
            row.original.banner ? (
                <Image src={`${apiUrlbase}/${row.original.banner}`} alt="Banner" width="100" preview downloadable={true} />
            ) : (
                <span className="text-center">---</span>
            ),
    },
    {
        header: "Translations",
        accessorKey: "categoryTranslates",
        cell: ({ row }) => (
            <TranslationCell translations={row.original.categoryTranslates} />
        ),
    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
            <div className="flex space-x-1">
                <Link to={`/dashboard/categories/edit/${row.original.id}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info p-button-sm"
                        tooltip="Edit"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Link to={`/dashboard/categories/delete/${row.original.id}`}>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-sm"
                        tooltip="Delete"
                        tooltipOptions={{ position: 'top' }}
                    />
                </Link>
            </div>
        ),
    },
];
