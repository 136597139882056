import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from "@context/AuthContext";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getUser } from "@utils/api/UserApi";
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Button as PrimeButton } from 'primereact/button';
import LoadingTable from '@components/Loading/LoadingTable';
import { Tag } from 'primereact/tag';
import { apiUrlbase } from '@helpers/Helpers';

interface UserData {
  email: string;
  username: string;
  fullName: string;
  photo: File | null; // Ensure that the photo is stored as a File object
}

const Profile: React.FC = () => {
  const { userToken } = useAuth();
  const toast = useRef<Toast | null>(null);
  const [photoPreview, setPhotoPreview] = useState<string | null>(null);

  // Fetch the user's data
  const { data, isLoading, error } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUser(userToken),
  });

  // Formik setup for form handling
  const formik = useFormik<UserData>({
    initialValues: {
      email: '',
      username: '',
      fullName: '',
      photo: null, // Initially null, will hold the File object
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      username: Yup.string().required('Username is required'),
      fullName: Yup.string().required('Full name is required'),
    }),
    onSubmit: async (values) => {
      console.log(values);  // File object is stored in values.photo

      // Try to submit the form with the actual File object in photo field
      // try {
      //   await updateUser(userToken, values);
      //   toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully' });
      // } catch (error) {
      //   toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to update profile' });
      // }
    },
  });

  // Set user data into Formik after data fetch
  useEffect(() => {
    if (data) {
      formik.setValues({
        email: data.data.email,
        username: data.data.username,
        fullName: data.data.fullName,
        photo: null, // No photo file initially from the API
      });
      setPhotoPreview(apiUrlbase + data.data.photo); // Set the preview for the user photo
    }
  }, [data]);

  // Handle photo selection and store the File object in Formik state
  const handlePhotoSelect = (e: any) => {
    const file = e.files[0]; // Get the selected file
    formik.setFieldValue('photo', file); // Store the actual File object
    setPhotoPreview(URL.createObjectURL(file)); // Set the preview using ObjectURL for display only
  };

  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

  // Custom item template for displaying the file information
  const itemTemplate = (file: any) => {
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2) + ' MB';
    return (
      <div className="flex items-center flex-col gap-1 ">
        <img alt={file.name} role="presentation" src={file.objectURL} width={150} height={100} />
        <Tag value={fileSizeInMB} severity="warning" className="px-2 py-1" />
      </div>
    );
  };

  if (isLoading) return <LoadingTable />;
  if (error) return <div>Error loading profile data</div>;

  return (
    <FormikProvider value={formik}>
      <div className="max-w-4xl mx-auto my-4">
        <Toast ref={toast} />
        <Card className="shadow-lg border-round-lg">
          <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
            <h2 className="text-xl font-semibold">Profile</h2>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div >
                <label className="block text-sm font-medium">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                  disabled
                />
              </div>

              <div >
                <label className="block text-sm font-medium">Username</label>
                <input
                  type="text"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                />
                {formik.errors.username && formik.touched.username && (
                  <p className="text-red-500 text-xs mt-1">{formik.errors.username}</p>
                )}
              </div>

              <div >
                <label className="block text-sm font-medium">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                />
                {formik.errors.fullName && formik.touched.fullName && (
                  <p className="text-red-500 text-xs mt-1">{formik.errors.fullName}</p>
                )}
              </div>

              <div className='w-1/2'>
                <label className="block text-sm font-medium">Photo</label>
                <FileUpload
                  name="photo"
                  accept="image/*"
                  customUpload
                  auto
                  itemTemplate={itemTemplate}
                  onSelect={handlePhotoSelect} // No Blob URL conversion here, raw File stored
                  chooseOptions={chooseOptions}
                  maxFileSize={1000000} // Limit size to 1MB
                />
                {photoPreview && (
                  <div className="mt-3">
                    <img src={photoPreview} alt="Profile Preview" className="w-32 h-32 object-cover rounded-full" />
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <PrimeButton label="Update Profile" icon="pi pi-check" className="p-button-success" />
            </div>
          </form>
        </Card>
      </div>
    </FormikProvider>
  );
};

export default Profile;
