import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import React from 'react';
import ProfileHeader from '@components/profile/ProfileHeader';
import GlobalSearch from '@components/Global/GlobalSearch';

type Propss = {
  setVisible: any;
};

const Header: React.FC<Propss> = ({ setVisible }) => {
  let logo = require('@resources/logo.png');
  
  return (
    <div className="p-3 bg-white shadow-md">
      {/* Mobile view: Logo on a full-width row */}
      <div className="flex justify-center mb-2 md:hidden">
        <Link to="/dashboard/home" className="w-full flex justify-center">
          <img alt="logo" src={logo} className="h-8 w-auto" />
        </Link>
      </div>

      {/* Menu button, logo, and profile section on the same row for desktop and mobile */}
      <div className="flex justify-between items-center">
        {/* Menu button aligned to the left */}
        <div className="md:w-1/3 flex justify-start">
          <Button
            label="Menu"
            icon="pi pi-arrow-right"
            className="p-button p-button-success p-button-rounded"
            style={{ padding: '0.75rem 1.5rem' }}
            onClick={() => setVisible(true)}
          />
        </div>

        {/* Logo centered on desktop, hidden on mobile (already rendered above in full width) */}
        <div className="hidden md:flex md:w-1/3 justify-center">
          <Link to="/dashboard/home" className="flex items-center">
            <img alt="logo" src={logo} className="h-8 md:h-12 w-auto" />
          </Link>
        </div>

        {/* Profile aligned to the right */}
        <div className="md:w-1/3 flex justify-end">
          <ProfileHeader hideEmailOnMobile={true} />
        </div>
      </div>

      <div>
        <GlobalSearch />
      </div>
    </div>
  );
};

export default Header;
