
import * as Yup from 'yup';



export const validationSchema = Yup.object({
    name: Yup.string().required('Language name is required'),
    code: Yup.string()
        .required('Language code is required')
        .max(2, 'Language code should be 2 characters')
        .matches(/^[A-Za-z]+$/, 'Only alphabetic characters are allowed'),
});
