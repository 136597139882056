import React from "react";
import { Dialog } from "primereact/dialog";

interface ProductPriceModalProps {
    productPrices: any[]; // Array of product prices
    isVisible: boolean;
    onHide: () => void;
}

const ProductPriceModal: React.FC<ProductPriceModalProps> = ({ productPrices, isVisible, onHide }) => {
    return (
        <Dialog
            header="Product Prices"
            visible={isVisible}
            onHide={onHide}
            style={{ width: '30vw' }}
            dismissableMask={true}
            className="rounded-lg shadow-lg bg-gradient-to-r from-gray-50 to-white"
        >
            <div className="p-6">
                {productPrices.length > 0 ? (
                    <ul className="space-y-4">
                        {productPrices.map((price: any, index: number) => (
                            <li
                                key={index}
                                className="flex justify-between items-center p-4 rounded-lg bg-green-50 border border-green-200 hover:bg-green-100 transition-colors duration-150"
                            >
                                <span className="text-gray-700 font-medium">{price.country.name}:</span>
                                <span className="text-green-800 font-semibold">
                                    {price.price} {price.country.currency}
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500 text-center text-lg mt-4">No Prices Available</p>
                )}
            </div>
        </Dialog>
    );
};

export default ProductPriceModal;
