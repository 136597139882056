import React, { useState, useRef } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Button as PrimeButton } from 'primereact/button';
import { Tag } from 'primereact/tag';

interface UserAddFormData {
    username: string;
    email: string;
    fullName: string;
    roles: string[];
    photo: File | null;
}

const rolesOptions = [
    { label: 'ROLE_USER', value: 'ROLE_USER' },
    { label: 'ROLE_ADMIN', value: 'ROLE_ADMIN' },
    // Add more roles as needed
];

const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    fullName: Yup.string().required('Full name is required'),
    roles: Yup.array().min(1, 'Select at least one role').required('Roles are required'),
    photo: Yup.mixed().required('Photo is required'),
});

const UserAdd: React.FC = () => {
    const toast = useRef<Toast | null>(null);
    const [photoPreview, setPhotoPreview] = useState<string | null>(null);

    const formik = useFormik<UserAddFormData>({
        initialValues: {
            username: '',
            email: '',
            fullName: '',
            roles: [],
            photo: null,
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log(values);
            // Handle form submission (e.g., API call to add user)
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'User added successfully' });
        },
    });

    const handlePhotoSelect = (e: any) => {
        const file = e.files[0];
        formik.setFieldValue('photo', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPhotoPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

    const itemTemplate = (file: any) => {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2) + ' MB';
        return (
            <div className="flex items-center flex-col gap-1">
                <img alt={file.name} role="presentation" src={file.objectURL} width={150} />
                <Tag value={fileSizeInMB} severity="warning" className="px-2 py-1" />
            </div>
        );
    };

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Toast ref={toast} />
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Add User</h2>
                    </div>

                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {/* Username Field */}
                            <div>
                                <label className="block text-sm font-medium">Username</label>
                                <InputText
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                />
                                {formik.errors.username && formik.touched.username && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.username}</p>
                                )}
                            </div>

                            {/* Email Field */}
                            <div>
                                <label className="block text-sm font-medium">Email</label>
                                <InputText
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.email}</p>
                                )}
                            </div>

                            {/* Full Name Field */}
                            <div>
                                <label className="block text-sm font-medium">Full Name</label>
                                <InputText
                                    name="fullName"
                                    value={formik.values.fullName}
                                    onChange={formik.handleChange}
                                    className="p-2 w-full border rounded-md shadow-sm bg-gray-50"
                                />
                                {formik.errors.fullName && formik.touched.fullName && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.fullName}</p>
                                )}
                            </div>

                            {/* Roles Field */}
                            <div className="md:col-span-2 lg:col-span-3">
                                <label className="block text-sm font-medium">Roles</label>
                                <MultiSelect
                                    value={formik.values.roles}
                                    options={rolesOptions}
                                    onChange={(e) => formik.setFieldValue('roles', e.value)}
                                    className="w-full"
                                    placeholder="Select Roles"
                                />
                                {formik.errors.roles && formik.touched.roles && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.roles}</p>
                                )}
                            </div>

                            {/* Photo Field */}
                            <div className="md:col-span lg:col-span-3 w-1/2">
                                <label className="block text-sm font-medium">Photo</label>
                                <FileUpload
                                    name="photo"
                                    accept="image/*"
                                    customUpload
                                    auto
                                    itemTemplate={itemTemplate}
                                    onSelect={handlePhotoSelect}
                                    chooseOptions={chooseOptions}
                                    maxFileSize={1000000} // Limit size to 1MB
                                />
                                {photoPreview && (
                                    <div className="mt-3">
                                        <img src={photoPreview} alt="Photo Preview" className="w-32 h-32 object-cover rounded-full" />
                                    </div>
                                )}
                                {formik.errors.photo && formik.touched.photo && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.photo}</p>
                                )}
                            </div>
                        </div>

                        <div className="flex justify-end mt-4">
                            <PrimeButton label="Add User" icon="pi pi-check" className="p-button-success" />
                        </div>
                    </form>
                </Card>
            </div>
        </FormikProvider>
    );
};

export default UserAdd;
