import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "@context/AuthContext";

export const HomeRoute = () => {

    let { userToken } = useAuth();
    let outlet = useOutlet();

    if (userToken) {
        return <Navigate to="/dashboard/home" />;
    }

    return (
        <>
            {outlet}
        </>
    )
};