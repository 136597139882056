import { Route, Routes } from 'react-router-dom';
import Login from '@pages/Login';
import Home from '@pages/Home';
import { ProtectedRoute } from '@routes/ProtectedRoute';
import { HomeRoute } from '@routes/HomeRoute';
import Profile from '@pages/Profile';
import CategoryList from '@pages/Categories/CategoriesList';
import NotAuthorized from '@pages/NotAuthorized';
import CategoriesAdd from '@pages/Categories/CategoriesAdd';
import UserList from '@pages/users/UserList';
import UserAdd from '@pages/users/UserAdd';
import LanguagesList from '@pages/setting/language/LanguageList';
import LanguageAdd from '@pages/setting/language/LanguageAdd';
import LanguageEdit from '@pages/setting/language/LanguageEdit';
import LanguageDeletePage from '@pages/setting/language/LanguageDeletePage';
import CategoriesDeletePage from '@pages/Categories/CategoriesDeletePage';
import CategoriesEdit from '@pages/Categories/CategoriesEdit';
import ProductList from '@pages/Products/ProductList';
import ProductDetails from '@pages/Products/ProductDetails';
import CountriesList from '@pages/setting/countries/CountriesList';
import CountryAdd from '@pages/setting/countries/CountryAdd';
import CountryEdit from '@pages/setting/countries/CountryEdit';
import CountryDeletePage from '@pages/setting/countries/CountryDeletePage';


function MainNavigation() {


  return (

    <Routes>

      <Route path='/not-authorized' element={<NotAuthorized />} />

      <Route element={<HomeRoute />}>
        <Route path='/' element={<Login />} />
      </Route>
      <Route path='/dashboard' element={<ProtectedRoute roles={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', "ROLE_USER"]} />}>
        <Route path='home' element={<Home />} />
        <Route path='profile' element={<Profile />} />
        <Route path='categories' element={<CategoryList />} />
        <Route path='categories/add' element={<CategoriesAdd />} />
        <Route path='categories/edit/:id' element={<CategoriesEdit />} />
        <Route path='categories/delete/:id' element={<CategoriesDeletePage />} />
        <Route path='products' element={<ProductList />} />
        <Route path='products/details/:id' element={<ProductDetails />} />
        <Route path='users' element={<UserList />} />
        <Route path='users/add' element={<UserAdd />} />
        <Route path='setting/languages' element={<LanguagesList />} />
        <Route path='setting/languages/add' element={<LanguageAdd />} />
        <Route path='setting/languages/edit/:id' element={<LanguageEdit />} />
        <Route path='setting/languages/delete/:id' element={<LanguageDeletePage />} />
        <Route path='setting/countries' element={<CountriesList />} />
        <Route path='setting/countries/add' element={<CountryAdd />} />
        <Route path='setting/countries/edit/:id' element={<CountryEdit />} />
        <Route path='setting/countries/delete/:id' element={<CountryDeletePage />} />
      </Route>


      <Route path='/dashboard/commercial' element={<ProtectedRoute roles={['ROLE_COMMERCIAL']} />}>
        <Route path='home' element={<Home />} />
      </Route>

      <Route path='/dashboard/livreur' element={<ProtectedRoute roles={['ROLE_LIVREUR']} />}>
        <Route path='home' element={<Home />} />
      </Route>
    </Routes>
  );
}

export default MainNavigation;
