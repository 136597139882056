import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-green/theme.css";
import 'primereact/resources/primereact.min.css';          
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from '@context/AuthContext';
import MainNavigation from '@navigation/MainNavigation';
import { ToastContainer } from '@mbs-dev/react-helpers';
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <BrowserRouter>
      <PrimeReactProvider>
        <AuthProvider>
          <ToastContainer />
          <MainNavigation/>
        </AuthProvider>
      </PrimeReactProvider>
    </BrowserRouter>
  );
}

export default App;
