// ProductImages.tsx
import React from 'react';
import { Image } from 'primereact/image';
import { Carousel } from 'primereact/carousel';

interface ProductImagesProps {
    mainImage: string;
    images: any[];
    title: string;
}

const ProductImages: React.FC<ProductImagesProps> = ({ mainImage, images, title }) => {
    const carouselImages = images.map((img: any) => ({
        src: img.image,
        alt: 'Produit',
    }));

    const carouselItemTemplate = (item: any) => (
        <div className="carousel-item">
            <Image src={item.src} alt={item.alt} width="100%" className="rounded-md shadow-md" preview />
        </div>
    );

    return (
        <div className="border border-green-200 p-4 rounded-lg bg-white shadow">
            {/* Image principale avec styles responsive */}
            <div className="w-full h-auto mb-4">
                <Image
                    src={mainImage}
                    alt={title}
                    className="w-full h-full object-cover rounded-lg"
                    preview
                />
            </div>

            {/* Carousel des images supplémentaires, ajusté pour le responsive */}
            {carouselImages.length > 0 && (
                <div className="mt-4">
                    <Carousel
                        value={carouselImages}
                        numVisible={1}
                        numScroll={1}
                        itemTemplate={carouselItemTemplate}
                        circular
                        autoplayInterval={3000}
                        className="p-carousel-custom"
                        responsiveOptions={[
                            { breakpoint: '1024px', numVisible: 3, numScroll: 1 },
                            { breakpoint: '768px', numVisible: 2, numScroll: 1 },
                            { breakpoint: '560px', numVisible: 1, numScroll: 1 }
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

export default ProductImages;
