import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import imageNotFound from "@resources/imageNotFound.png";
import VariantCell from "./cells/VariantCell";
import ProductPriceModal from "./cells/ProductPriceModal";
import ProductTranslatesModal from "./cells/ProductTranslatesModal";
import { apiUrlbase } from "@helpers/Helpers";

export const columns: ColumnDef<any>[] = [
    {
        header: "ID",
        accessorKey: "id",
        cell: ({ row }) => <span className="text-sm">{row.original.id}</span>,
    },
    {
        header: "Reference",
        accessorKey: "reference",
        cell: ({ row }) => <span className="text-sm">{row.original.reference}</span>,
    },
    {
        header: "Stock",
        accessorKey: "stock",
        cell: ({ row }) => <span className="text-sm">{row.original.stock}</span>,
    },
    {
        header: "Image",
        accessorKey: "productTranslates[0].image",
        cell: ({ row }) =>
            row.original.productTranslates[0].image ? (
                <Image src={`${apiUrlbase}/${row.original.productTranslates[0].image}`} alt="Product" width="100" preview downloadable={true} />
            ) : (
                <Image src={imageNotFound} alt="Image Not Found" width="100" />
            ),
    },
    {
        header: "Price",
        accessorKey: "productPrice[0].price",
        cell: ({ row }) => <PriceButton productPrices={row.original.productPrice} />, // Button to trigger price modal
    },
    {
        header: "Translations",
        accessorKey: "productTranslates",
        cell: ({ row }) => <TranslationButton productTranslates={row.original.productTranslates} />, // Button to trigger translation modal
    },
    {
        header: "Variants",
        accessorKey: "variant",
        cell: ({ row }) => <VariantCell variants={row.original.variant} />,
    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
            <div className="flex space-x-1">
                <Link to={`/dashboard/products/details/${row.original.id}`}>
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-help p-button-sm"
                        tooltip="Voir les détails"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Link to={`/dashboard/products/edit/${row.original.id}`}>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info p-button-sm"
                        tooltip="Edit"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
                <Link to={`/dashboard/products/delete/${row.original.id}`}>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-sm"
                        tooltip="Delete"
                        tooltipOptions={{ position: "top" }}
                    />
                </Link>
            </div>
        ),
    },
];

const PriceButton: React.FC<{ productPrices: any[] }> = ({ productPrices }) => {
    const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
    return (
        <>
            <Button
                label="View Prices"
                className="p-button-sm p-button-success"
                onClick={() => setIsPriceModalVisible(true)}
            />
            <ProductPriceModal
                productPrices={productPrices}
                isVisible={isPriceModalVisible}
                onHide={() => setIsPriceModalVisible(false)}
            />
        </>
    );
};

const TranslationButton: React.FC<{ productTranslates: any[] }> = ({ productTranslates }) => {
    const [isTranslateModalVisible, setIsTranslateModalVisible] = useState(false);
    return (
        <>
            <Button
                label="View Translations"
                className="p-button-sm p-button-info"
                onClick={() => setIsTranslateModalVisible(true)}
            />
            <ProductTranslatesModal
                productTranslates={productTranslates}
                isVisible={isTranslateModalVisible}
                onHide={() => setIsTranslateModalVisible(false)}
            />
        </>
    );
};
