import { API } from "./API_INSTANCE"

type body = {
    email:string,
    password:string
}

export const Authenticate = (body:body) => {
   return API.post('/login',body,{
        headers: {
            'Content-Type':'application/json'
        }
    })
    .then( (res) => res )
    .catch( err => { throw err } )
}