import React, { useState } from "react";
import { Dialog } from "primereact/dialog"; // PrimeReact modal
import { Button } from "primereact/button";
import { variantColumns } from "@components/products/variantColumns"; // Variant columns
import { Image } from "primereact/image"; // PrimeReact Image
import VariantAttributesButton from "../variants/VariantAttributesButton";
import VariantAttributesModal from "../variants/VariantAttributesModal";

interface VariantCellProps {
    variants: any[]; // The variants array for the specific product
}

const VariantCell: React.FC<VariantCellProps> = ({ variants }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAttributesModalOpen, setIsAttributesModalOpen] = useState(false); // For variant attributes modal
    const [selectedVariantAttributes, setSelectedVariantAttributes] = useState<any[]>([]); // For selected variant's attributes

    // Open the modal
    const openVariantModal = () => {
        setIsModalOpen(true);
    };

    // Close the modal
    const closeVariantModal = () => {
        setIsModalOpen(false);
    };

    // Open the attributes modal
    const openAttributesModal = (attributes: any[]) => {
        setSelectedVariantAttributes(attributes);
        setIsAttributesModalOpen(true);
    };

    // Close the attributes modal
    const closeAttributesModal = () => {
        setIsAttributesModalOpen(false);
    };

    return (
        <>
            {/* Button to trigger the modal */}
            <Button
                icon="pi pi-list"
                className="p-button-rounded p-button-success p-button-sm"
                tooltip="View Variants"
                onClick={openVariantModal}
                tooltipOptions={{ position: "top" }}
            />

            {/* Modal to display the variants */}
            <Dialog
                header="Product Variants"
                visible={isModalOpen}
                onHide={closeVariantModal}
                style={{ width: '60vw' }}
                dismissableMask={true} // Allows closing when clicking outside
            >
                <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                    <thead>
                        <tr className="bg-gray-200 text-black uppercase text-xs text-center leading-tight">
                            {variantColumns.map((col) => (
                                <th key={col.header} className="py-2 px-4 text-left">
                                    {col.header}
                                </th>
                            ))}
                            <th className="py-2 px-4 text-left">Description</th>
                            <th className="py-2 px-4 text-left">Attributes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variants.length > 0 ? (
                            variants.map((variant) => (
                                <tr key={variant.id} className="border-b hover:bg-gray-50 transition ease-in-out text-sm">
                                    {variantColumns.map((col) => (
                                        <td key={col.accessorKey} className="py-2 px-4 text-left">
                                            {variant[col.accessorKey] || "N/A"}
                                        </td>
                                    ))}
                                    {/* Description with truncation */}
                                    <td className="py-2 px-4 text-left">
                                        {variant.description.length > 50
                                            ? variant.description.substring(0, 50) + "..."
                                            : variant.description}
                                    </td>
                                    {/* Attributes Button */}
                                    <td className="py-2 px-4 text-left">
                                        <VariantAttributesButton
                                            onClick={() => openAttributesModal(variant.variantAttributes)}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={variantColumns.length + 3} className="text-center p-4">
                                    No Variants Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Dialog>


            {/* Modal to display variant attributes */}
            <VariantAttributesModal
                attributes={selectedVariantAttributes}
                isVisible={isAttributesModalOpen}
                onHide={closeAttributesModal}
            />


        </>
    );
};

export default VariantCell;
