import { apiUrlbase } from "@helpers/Helpers";
import { ColumnDef } from "@tanstack/react-table";
import { User } from "@utils/interfaces/User";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";

export const userColumns: ColumnDef<User>[] = [
    {
        header: "ID",
        accessorKey: "id",
        cell: ({ row }) => <span className="text-sm">{row.original.id}</span>,
    },
    {
        header: "Photo",
        accessorKey: "photo",
        cell: ({ row }) =>
            row.original.photo ? (
                <Image src={apiUrlbase + row.original.photo} alt="User" width="80" preview downloadable={true} />
            ) : (
                <Tag value="No Image" severity="danger" />
            ),
    },
    {
        header: "Email",
        accessorKey: "email",
        cell: ({ row }) => <span className="text-sm">{row.original.email}</span>,
    },
    {
        header: "Username",
        accessorKey: "username",
        cell: ({ row }) => <span className="text-sm">{row.original.username}</span>,
    },
    {
        header: "Full Name",
        accessorKey: "fullName",
        cell: ({ row }) => <span className="text-sm">{row.original.fullName}</span>,
    },
    {
        header: "Roles",
        accessorKey: "roles",
        cell: ({ row }) =>
            row.original.roles.length > 0 ? (
                <div className="flex gap-1">
                    {row.original.roles.map((role: string, index: number) => (
                        <Tag key={index} value={role} severity="info" className="text-xs" />
                    ))}
                </div>
            ) : (
                <Tag value="No Roles" severity="warning" className="text-xs" />
            ),
    },
    {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
            <div className="flex space-x-1">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info p-button-sm"
                    tooltip="Edit"
                    tooltipOptions={{ position: "top" }}
                // onClick={() => handleEdit(row.original.id)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-sm"
                    tooltip="Delete"
                    tooltipOptions={{ position: "top" }}
                // onClick={() => handleDelete(row.original.id)}
                />
            </div>
        ),
    },
];
