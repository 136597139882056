import { Category } from "@utils/interfaces/category";
import { API } from "./API_INSTANCE"
import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";
import { Country } from "@utils/interfaces/Country";



export interface AddCountryPayload {
    name: string;
    code: string;
    currency : string ;
}


export const addCountry = async (apiRequest: any, data: AddCountryPayload) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/countries`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });

        notify.successNotify("Country create successfully");
    } catch (error) {
        notify.errorNotify("error in create Country")
    }
};

export const updateCountry = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/countries/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('country mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour country');
    }
};

export const getCountry= async (apiRequest: any, id: any): Promise<Country> => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/countries/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Country Not Found !!');
        throw new Error('Erreur lors de la récupération de payée');
    }
};


export const deleteCountry = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/countries/${id}.json`,
            method: 'DELETE',
        });
        notify.successNotify('Country supprimée avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la suppression de la payee');
        throw new Error('Erreur lors de la suppression de la langue');
    }
};
