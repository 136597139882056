import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import LoadingTable from "@components/Loading/LoadingTable";
import ErrorLoadingData from "@components/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";
import { columns } from "@components/products/columns";

// Filters for products
interface FilterProducts {
    reference: string;
    item_page: number;
}

const ProductList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    // States for filters
    const [filters, setFilters] = useState<FilterProducts>({
        reference: '',
        item_page: 10,
    });

    const [totalItems, setTotalItems] = useState(0);

    // Pagination system
    const { currentPage, totalPages, setPage } = usePagination({ totalItems, itemsPerPage: filters.item_page });

    const params = useMemo(() => ({
        page: currentPage,
        'items_per_page': filters.item_page,
        ...(filters.reference && { reference: filters.reference }),
    }), [filters, currentPage]);

    const fetchFilteredProducts = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/products.jsonld`,
            method: 'GET',
            params: params,
            headers: {
                Accept: 'application/ld+json',
            },
        });

        if (response.data && response.data['hydra:totalItems']) {
            setTotalItems(response.data['hydra: totalItems']);
        } else {
            setTotalItems(0);
        }

        return response.data ? response.data['hydra:member'] : [];
    }, [apiRequest, params]);

    const { data, isLoading, error } = useQuery({
        queryKey: ['products', params],
        queryFn: fetchFilteredProducts
    });

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const debouncedHandleFilterChange = useMemo(() =>
        debounce((key: string, value: any) => {
            setFilters(prevFilters => ({
                ...prevFilters,
                [key]: key === 'item_page' ? parseInt(value, 10) : value,
            }));
            setPage(1);
        }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="products" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Product List</h2>
            <Button
                label="Add Product"
                icon="pi pi-plus"
                className="p-button-sm p-button-outlined"
                onClick={() => navigate("/dashboard/products/add")}
            />
        </div>
    );

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg p-2">
                {/* Filters */}
                <div className="px-4 py-3">
                    <div className="flex flex-row gap-3">
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="reference"
                                name="reference"
                                placeholder="Filter by reference"
                                defaultValue={filters.reference}
                                onChange={(event) => debouncedHandleFilterChange('reference', event.target.value)}
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="number"
                                id="item_page"
                                name="item_page"
                                placeholder="Items per page"
                                defaultValue={filters.item_page}
                                onChange={(event) => debouncedHandleFilterChange('item_page', event.target.value)}
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-green-400 text-white uppercase text-xs text-center leading-tight">
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id} className="border-b hover:bg-green-50 transition ease-in-out text-sm">
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
            </Panel>
        </div>
    );
};

export default ProductList;
