import * as Yup from 'yup';

export const CountrySchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Le nom du pays doit comporter au moins 2 caractères.')
        .max(50, 'Le nom du pays ne peut pas dépasser 50 caractères.')
        .required('Le nom du pays est requis.'),

    code: Yup.string()
        .matches(/^[A-Z]{2,3}$/, 'Le code du pays doit comporter 2 ou 3 lettres majuscules.')
        .required('Le code du pays est requis.'),

    currency: Yup.string()
        .length(3, 'Le code de la devise doit comporter exactement 3 lettres.')
        .matches(/^[A-Z]{3}$/, 'Le code de la devise doit comporter 3 lettres majuscules.')
        .required('Le code de la devise est requis.'),
});
