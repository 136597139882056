import React from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Button as PrimeButton } from 'primereact/button';
import { CategoriesAddSchema } from '../../utils/schemas/CategoriesSchema';
import { CategoriesIV } from '@utils/initValues/CategoriesIV';
import ErrorLoadingData from '@components/Errors/ErrorLoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { fetchLanguages } from '@utils/api/Lang';
import CategoryTranslationFields from '@components/categories/add/CategoryTranslationFields';
import FileUploadWithPreview from '@components/Form/FileUploadWithPreview';
import LoadingData from '@components/Loading/LoadingData';
import { useNavigate, useNavigation } from 'react-router-dom';
import { notify } from '@mbs-dev/react-helpers';
import { addCategory } from '@utils/api/category';

interface Language {
    id: number;
    name: string;
    code: string;
}

interface CategoryFormData {
    image: File | null;
    banner: File | null;
    categoryTranslates: {
        title: string;
        slug: string;
        language: string;
        description: string;
    }[];
}

const CreateCategoryForm: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { data: languages, isLoading, error } = useQuery<Language[]>({
        queryKey: ['languages'],
        queryFn: () => fetchLanguages(apiRequest),
    });

    // Mutation pour soumettre les données
    const mutation = useMutation({
        mutationFn: (data: FormData) => addCategory(apiRequest, data), // Remplacer par ta fonction API
        onSuccess: () => {
            navigate('/dashboard/categories');
            queryClient.invalidateQueries({
                queryKey: ["categories"]
            });
        },
        onError: (error: Error) => {
            notify.errorNotify("Erreur lors de la création de la catégorie");
        },
    });

    const formik = useFormik<CategoryFormData>({
        initialValues: CategoriesIV,
        validationSchema: CategoriesAddSchema,
        onSubmit: (values) => {
            const formData = new FormData();

            // Ajouter l'image et la bannière si elles existent
            if (values.image) {
                formData.append('image', values.image);
            }

            if (values.banner) {
                formData.append('banner', values.banner);
            }

            // Ajouter les traductions dans FormData
            values.categoryTranslates.forEach((translate, index) => {
                formData.append(`categoryTranslates[${index}][title]`, translate.title);
                formData.append(`categoryTranslates[${index}][slug]`, translate.slug);
                formData.append(`categoryTranslates[${index}][language]`, `api/languages/${translate.language}` );
                formData.append(`categoryTranslates[${index}][description]`, translate.description);
            });

            // console.log(formData);
            
            mutation.mutate(formData);
        },
    });

    const handleFileSelect = (setFieldValue: (field: string, value: any) => void, fieldName: string) => (file: File | null) => {
        setFieldValue(fieldName, file);
    };



    if (error) return <ErrorLoadingData entity="languages" />;

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Create Category</h2>
                    </div>

                    <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm">
                        <div className="flex flex-col gap-4 md:flex-row">
                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Category Image :</label>
                                <FileUploadWithPreview
                                    label="Choose Category Image"
                                    onFileSelect={handleFileSelect(formik.setFieldValue, "image")}
                                />
                                {formik.touched.image && formik.errors.image && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.image}</p>
                                )}
                            </div>

                            <div className="md:w-1/2 w-full">
                                <label className="block text-sm font-medium mb-2">Banner Image :</label>
                                <FileUploadWithPreview
                                    label="Choose Banner Image"
                                    onFileSelect={handleFileSelect(formik.setFieldValue, "banner")}
                                />
                                {formik.touched.banner && formik.errors.banner && (
                                    <p className="text-red-500 text-xs mt-1">{formik.errors.banner}</p>
                                )}
                            </div>
                        </div>

                        <div className='mt-6'>
                            {isLoading ? (
                                <LoadingData />
                            ) : (
                                <CategoryTranslationFields
                                    formik={formik}
                                    languages={languages || []}
                                />
                            )}
                        </div>

                        <div className="flex justify-center">
                            <PrimeButton label="Create Category" icon="pi pi-check" className="p-button-success text-xs" />
                        </div>
                    </form>
                </Card>
            </div>
        </FormikProvider>
    );
};

export default CreateCategoryForm;
