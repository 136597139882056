// CountryFlag.tsx
import React from 'react';

interface CountryFlagProps {
    countryCode: string ;
    size?: number; // Taille du drapeau, par défaut à 24
}

// Mappage des codes de pays aux noms de pays pour référence
const countryCodes: { [key: string]: string } = {
    FR: 'FR',
    MAR: 'MA',
    USA: 'US'
};

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, size = 24 }) => {
    // Construire l'URL du drapeau en utilisant le code du pays
    const flagUrl =countryCodes[countryCode] ?  `https://flagsapi.com/${countryCodes[countryCode]}/flat/64.png` : `https://flagsapi.com/MA/flat/64.png`

    return (
        <img
            src={flagUrl}
            alt={`${countryCodes[countryCode] || countryCode} flag`}
            width={size}
            height={size}
            className="inline-block"
            style={{ borderRadius: '3px' }}
        />
    );
};

export default CountryFlag;
