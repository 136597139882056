import * as Yup from 'yup';
import { ImgAllowed } from './ImageType';



export const CategoriesAddSchema = Yup.object().shape({
    image: Yup.mixed()
        .required('Category Image is required')
        .test('fileType', 'Only image files are allowed', (value:any) =>
            value ? ImgAllowed.includes(value.type) : false
        )
        .test('fileSize', 'Image size must be less than 2 MB', (value:any) =>
            value ? value.size <= 2000000 : false
        ),
    banner: Yup.mixed()
        .required('Banner Image is required')
        .test('fileType', 'Only image files are allowed', (value:any) =>
            value ? ImgAllowed.includes(value.type) : false
        )
        .test('fileSize', 'Banner size must be less than 2 MB', (value:any) =>
            value ? value.size <= 2000000 : false
        ),
    categoryTranslates: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is required'),
            slug: Yup.string().required('Slug is required'),
            language: Yup.string().required('Language is required'),
            description: Yup.string().required('description is required'),
        })
    ),
});