import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { notify } from '@mbs-dev/react-helpers';
import useApiRequest from '@mbs-dev/api-request';
import { deleteCategories } from '@utils/api/category';

const CategoriesDelete: React.FC<{ id: string | undefined }> = ({ id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    const mutation = useMutation({
        mutationFn: () => deleteCategories(apiRequest, id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['categories'] });
            navigate('/dashboard/categories');
        },
    });

    const handleDelete = () => {
        setIsModalOpen(true);
    };

    const confirmDelete = () => {
        mutation.mutate();
        setIsModalOpen(false);
    };

    const cancelDelete = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='flex justify-center'>
            <Button
                label="Supprimer category"
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={handleDelete}
            />


            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
                        <h2 className="text-xl font-semibold mb-4">Confirmer la suppression</h2>
                        <p className="text-gray-600 mb-6">Êtes-vous sûr de vouloir supprimer cette category ?</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={confirmDelete}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
                            >
                                Supprimer
                            </button>
                            <button
                                onClick={cancelDelete}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition-colors"
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoriesDelete;
