import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@context/AuthContext'


type ProfileHeaderProps = {
  hideEmailOnMobile?: boolean;
};


const ProfileHeader: React.FC<ProfileHeaderProps> = ({ hideEmailOnMobile = false }) => {
    let {user} = useAuth()
    let userPFP = require("@resources/user.jpg")
  return (
    <Link to="/dashboard/profile" className="flex flex-row justify-content-center align-items-center gap-2 p-3  hover:bg-green-100 rounded-lg my-2 flex-wrap">
        {!hideEmailOnMobile && (
        <span className="hidden md:block">{user?.username}</span> 
      )}
        <img alt="logo" src={userPFP} className="me-2 h-10 rounded-full" />
    </Link>
  )
}

export default ProfileHeader