import React from 'react';
import { useParams } from 'react-router-dom'
import CategoriesDelete from '@components/categories/delete/CategoriesDelete';

const CategoriesDeletePage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <div className="max-w-md mx-auto my-4">
            <h2 className="text-xl font-semibold text-center mb-4">Supprimer category</h2>
            <CategoriesDelete id={id} />
        </div>
    );
};

export default CategoriesDeletePage;
