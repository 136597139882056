import React, { memo, useMemo } from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    maxPagesToShow?: number;
}

const Pagination: React.FC<PaginationProps> = memo(
    ({ currentPage, totalPages, onPageChange, maxPagesToShow = 3 }) => {
        const pageNumbers = useMemo(() => {
            const pages = [];
            let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
            let endPage = startPage + maxPagesToShow - 1;

            if (endPage > totalPages) {
                endPage = totalPages;
                startPage = Math.max(1, endPage - maxPagesToShow + 1);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }, [currentPage, totalPages, maxPagesToShow]);

        return (
            <div className="flex justify-center items-center mt-6 space-x-2">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    aria-label="Previous Page"
                    className={`p-button p-button-sm ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-400 hover:bg-green-500 focus:ring-2 focus:ring-green-300'} transition-colors duration-150`}
                >
                    Previous
                    {/* <i className="pi pi-chevron-left" /> */}
                </button>

                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => onPageChange(page)}
                        aria-label={`Page ${page}`}
                        className={`p-button p-button-sm ${page === currentPage ? 'bg-green-500 text-white' : 'bg-white text-green-600 hover:bg-green-400 hover:text-white focus:ring-2 focus:ring-green-300'} transition-all duration-150 rounded-md px-3`}
                    >
                        {page}
                    </button>
                ))}

                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    aria-label="Next Page"
                    className={`p-button p-button-sm ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-400 hover:bg-green-500 focus:ring-2 focus:ring-green-300'} transition-colors duration-150`}
                >
                    Next
                    {/* <i className="pi pi-chevron-right" /> */}
                </button>
            </div>
        );
    }
);

export default Pagination;
