import React from 'react';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { addLanguage } from '@utils/api/Lang';
import useApiRequest from '@mbs-dev/api-request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { validationSchema } from '../../../utils/schemas/LanguageSchema';
import { LangIV } from '@utils/initValues/LanguageIV';
import { notify } from '@mbs-dev/react-helpers';

// Définir le type des données du formulaire
interface LanguageData {
    name: string;
    code: string;
}

const LanguageAdd: React.FC = () => {
    const queryClient = useQueryClient();
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();

    // Utilisation de la mutation pour ajouter une langue
    const mutation = useMutation({
        mutationFn: (data: LanguageData) => addLanguage(apiRequest, data),
        onSuccess: () => {
            navigate('/dashboard/setting/languages');
            queryClient.invalidateQueries({
                queryKey: ["languages"]
            });
        },
        onError: (error: Error) => {
            notify.errorNotify("Erreur lors de la création de la langue");
        },
    });

    // Initialiser Formik
    const formik = useFormik<LanguageData>({
        initialValues: LangIV,
        validationSchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
    });

    return (
        <div className="max-w-md mx-auto my-4">
            <Card className="shadow-lg border-round-lg">
                <div className="bg-green-100 text-center border-2 border-green-200 rounded-lg p-3">
                    <h2 className="text-xl font-semibold">Ajouter une nouvelle langue</h2>
                </div>

                <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm border-2 border-green-100 rounded-lg">
                    <div>
                        <label className="block text-sm font-medium mb-2">Nom</label>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.name && formik.touched.name ? 'p-invalid' : ''}`}
                            placeholder="Entrez le nom de la langue"
                        />
                        {formik.errors.name && formik.touched.name && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-2">Code</label>
                        <InputText
                            name="code"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            className={`p-inputtext w-full ${formik.errors.code && formik.touched.code ? 'p-invalid' : ''}`}
                            placeholder="Entrez le code de la langue (2 lettres)"
                        />
                        {formik.errors.code && formik.touched.code && (
                            <p className="text-red-500 text-xs mt-1">{formik.errors.code}</p>
                        )}
                    </div>

                    <div className="flex justify-end">
                        <Button
                            label="Ajouter la langue"
                            icon="pi pi-check"
                            className="p-button-success"
                            type="submit"
                            disabled={mutation.isPending}
                        />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default LanguageAdd;
