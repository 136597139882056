import React from "react";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image"; // PrimeReact Image for showing product images

interface ProductTranslatesModalProps {
    productTranslates: any[]; // Array of product translations
    isVisible: boolean;
    onHide: () => void;
}

const ProductTranslatesModal: React.FC<ProductTranslatesModalProps> = ({ productTranslates, isVisible, onHide }) => {
    return (
        <Dialog
            header="Product Translations"
            visible={isVisible}
            onHide={onHide}
            style={{ width: '50vw' }}
            dismissableMask={true}
            className="rounded-lg shadow-lg bg-gradient-to-r from-gray-50 to-white"
        >
            <div className="p-6">
                {productTranslates.length > 0 ? (
                    productTranslates.map((translate: any, index: number) => (
                        <div key={index} className="mb-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">
                                {translate.title} ({translate.language.name})
                            </h3>

                            {/* Product Images */}
                            <div className="flex space-x-4 mb-4">
                                {translate.productImages && translate.productImages.length > 0 ? (
                                    translate.productImages.map((img: any, imgIndex: number) => (
                                        <Image key={imgIndex} src={img.image} alt={translate.title} width="100" />
                                    ))
                                ) : (
                                    <p>No images available.</p>
                                )}
                            </div>

                            <p className="text-gray-600 mb-2">
                                <strong>Description: </strong>
                                {translate.description}
                            </p>

                            <p className="text-gray-600 mb-2">
                                <strong>How to Use: </strong>
                                {translate.howToUse}
                            </p>

                            <p className="text-gray-600 mb-2">
                                <strong>Ingredients: </strong>
                                {translate.ingredients}
                            </p>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500 text-center text-lg mt-4">No Translations Available</p>
                )}
            </div>
        </Dialog>
    );
};

export default ProductTranslatesModal;
