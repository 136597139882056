import React from 'react';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Card } from 'primereact/card';

function Home() {
  // Mock data for the chart
  const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'First Dataset',
        data: [60, 50, 70, 80, 45, 65, 85],
        fill: false,
        borderColor: '#3b82f6',
        tension: 0.4
      },
      {
        label: 'Second Dataset',
        data: [30, 40, 50, 60, 30, 50, 60],
        fill: false,
        borderColor: '#22c55e',
        tension: 0.4
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  // Mock sales data for the table
  const sales = [
    { image: 'bamboo_watch.jpg', name: 'Bamboo Watch', price: '$65.00' },
    { image: 'black_watch.jpg', name: 'Black Watch', price: '$72.00' },
    { image: 'blue_band.jpg', name: 'Blue Band', price: '$79.00' },
    { image: 'blue_tshirt.jpg', name: 'Blue T-Shirt', price: '$29.00' },
    { image: 'bracelet.jpg', name: 'Bracelet', price: '$15.00' }
  ];

  const renderImage = (rowData: any) => {
    return <img src={`/images/${rowData.image}`} alt={rowData.name} className="w-12 h-12 rounded" />;
  };

  const renderViewIcon = () => {
    return <Button icon="pi pi-search" className="p-button-rounded p-button-outlined p-button-secondary" />;
  };

  return (

    <div className="min-h-screen p-5">
      {/* Header Cards Section */}
      <div className="flex justify-center gap-4 mb-6">
        {/* More Cards */}
        <div className='w-full'>
          <Card className="surface-0 shadow-2 p-3 border-round ">
            <div className="flex justify-between items-center mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Orders</span>
                <div className="text-900 font-medium text-xl">152</div>
              </div>
              <div className="flex items-center justify-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">24 new </span>
            <span className="text-500">since last visit</span>
          </Card>
        </div>

        <div className='w-full'>
          <Card className="surface-0 shadow-2 p-3 border-round">
            <div className="flex justify-between items-center mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Revenue</span>
                <div className="text-900 font-medium text-xl">$2.100</div>
              </div>
              <div className="flex items-center justify-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-map-marker text-orange-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">%52+ </span>
            <span className="text-500">since last week</span>
          </Card>

        </div>
        <div className='w-full'>
          <Card className="surface-0 shadow-2 p-3 border-round">
            <div className="flex justify-between items-center mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Customers</span>
                <div className="text-900 font-medium text-xl">28,441</div>
              </div>
              <div className="flex items-center justify-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-inbox text-cyan-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">520 </span>
            <span className="text-500">newly registered</span>
          </Card>
        </div>

        <div className='w-full'>
          <Card className="surface-0 shadow-2 p-3 border-round">
            <div className="flex justify-between items-center mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Comments</span>
                <div className="text-900 font-medium text-xl">152 Unread</div>
              </div>
              <div className="flex items-center justify-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-comment text-purple-500 text-xl"></i>
              </div>
            </div>
            <span className="text-green-500 font-medium">85 </span>
            <span className="text-500">responded</span>
          </Card>
        </div>

      </div>

      {/* Recent Sales, Notifications, and Chart in the same row */}
      <div className="flex justify-between gap-5 mb-6">
        {/* Recent Sales Table */}
        <div className='w-full'>
          <Card className="surface-0 shadow-2 p-4">
            <h2 className="text-2xl font-semibold mb-4">Recent Sales</h2>
            <DataTable value={sales} responsiveLayout="scroll" paginator rows={5}>
              <Column field="image" header="Image" body={renderImage}></Column>
              <Column field="name" header="Name" sortable></Column>
              <Column field="price" header="Price" sortable></Column>
              <Column header="View" body={renderViewIcon}></Column>
            </DataTable>
          </Card>
        </div>
        {/* Notifications */}
        <div className='grow'>
          <Card className="surface-0 shadow-2 p-4 h-full">
            <h2 className="text-2xl font-semibold mb-4">Notifications</h2>

            {/* Notification 1 */}
            <div className="bg-blue-50 p-3 rounded-lg mb-3 flex items-center">
              <div className="mr-3">
                <i className="pi pi-dollar text-blue-500 text-xl"></i>
              </div>
              <div className="text-lg text-blue-900">
                <p>Richard Jones has purchased a blue t-shirt for <span className="font-bold">$79</span></p>
                <span className="text-sm text-blue-600">2 mins ago</span>
              </div>
            </div>

            {/* Notification 2 */}
            <div className="bg-green-50 p-3 rounded-lg mb-3 flex items-center">
              <div className="mr-3">
                <i className="pi pi-user-plus text-green-500 text-xl"></i>
              </div>
              <div className="text-lg text-green-900">
                <p>Sarah Smith has just signed up</p>
                <span className="text-sm text-green-600">10 mins ago</span>
              </div>
            </div>

            {/* Notification 3 */}
            <div className="bg-yellow-50 p-3 rounded-lg mb-3 flex items-center">
              <div className="mr-3">
                <i className="pi pi-exclamation-circle text-yellow-500 text-xl"></i>
              </div>
              <div className="text-lg text-yellow-900">
                <p>System maintenance is scheduled for 12:00 AM</p>
                <span className="text-sm text-yellow-600">1 hour ago</span>
              </div>
            </div>

            {/* Notification 4 */}
            <div className="bg-red-50 p-3 rounded-lg flex items-center">
              <div className="mr-3">
                <i className="pi pi-exclamation-triangle text-red-500 text-xl"></i>
              </div>
              <div className="text-lg text-red-900">
                <p>Server issue detected in region US-West</p>
                <span className="text-sm text-red-600">2 hours ago</span>
              </div>
            </div>
          </Card>
        </div>



      </div>
      {/* Sales Overview Chart */}
      <div className="flex justify-center gap-5 mb-6">
        <div className="w-full">
          <Card className="surface-0 shadow-2 p-4 h-full">
            <h2 className="text-2xl font-semibold mb-4">Sales Overview</h2>
            <div className="relative h-64">
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </Card>
        </div>

        <div className="w-full">
          <Card className="surface-0 shadow-2 p-4 h-full">
            <h2 className="text-2xl font-semibold mb-4">Sales Overview</h2>
            <div className="relative h-64">
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </Card>
        </div>
      </div>
    </div>

  );
}

export default Home;
