// ProductDetails.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiUrlbase } from '@helpers/Helpers';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import useApiRequest from '@mbs-dev/api-request';
import { fetchProduct } from '@utils/api/Product';
import ProductImages from '@components/products/details/ProductImages';
import ProductInfo from '@components/products/details/ProductInfo';
import VariantList from '@components/products/details/VariantList';

const ProductDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();
    const [selectedLanguage, setSelectedLanguage] = useState({ code: "en", name: "English" });

    const { data: product, isLoading, error } = useQuery({
        queryKey: ['product', id],
        queryFn: () => fetchProduct(apiRequest, id),
    });

    useEffect(() => {
        if (product && selectedLanguage.code === '') {
            const langs = product.productTranslates.map((translate: any) => ({
                code: translate.language.code,
                name: translate.language.name,
            }));
            if (langs.length > 0) setSelectedLanguage(langs[0]);
        }
    }, [product, selectedLanguage]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ProgressSpinner />
            </div>
        );
    }

    if (error) {
        return <p className="text-center text-red-600">Erreur : {(error as Error).message}</p>;
    }

    if (!product) return null;

    const languages = product.productTranslates.map((translate: any) => ({
        code: translate.language.code,
        name: translate.language.name,
    }));
    const selectedTranslation = product.productTranslates.find(
        (pt: any) => pt.language.code === selectedLanguage.code
    ) || product.productTranslates[0];
    const mainImage = `${apiUrlbase}/${selectedTranslation.image}`;
    const images = selectedTranslation.productImages.map((img: any) => ({
        ...img,
        image: `${apiUrlbase}/${img.image}`,
    }));
    const variants = product.variant.map((variant: any) => ({
        ...variant,
        image: `${apiUrlbase}/${variant.image}`,
        variantImages: variant.variantImages.map((img: any) => ({
            ...img,
            image: `${apiUrlbase}/${img.image}`,
        })),
    }));

    return (
        <div className="w-full flex justify-center flex-col align-items-center ">
            <div className="flex justify-center flex-col align-items-center w-fit">
                <div className='flex w-full  justify-between mt-6  items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg gap-2 md:gap-0'>
                    <Button
                        icon="pi pi-arrow-left"
                        onClick={() => navigate(-1)}
                        className="p-button-outlined"
                    />
                    <h1 className="text-lg font-semibold text-green-700">
                        {selectedTranslation.title}
                    </h1>
                    <div className='sm:block hidden'></div>
                </div>
                <div className="product-details px-6 pb-4 pt-4  bg-white  rounded-lg shadow-lg">
                    {/* Responsive Language Selection Buttons */}
                    <div className="flex flex-wrap gap-2 mb-5">
                        {languages.map((lang: any) => (
                            <button
                                key={lang.code}
                                onClick={() => setSelectedLanguage(lang)}
                                className={`px-4 py-2 rounded-lg text-sm ${selectedLanguage.code === lang.code
                                    ? 'bg-green-500 text-white font-semibold'
                                    : 'bg-gray-200 text-gray-700 hover:bg-green-300'
                                    }`}
                            >
                                {lang.name}
                            </button>
                        ))}
                    </div>

                    <div className="max-w-5xl mx-auto bg-white p-4 rounded-lg shadow-md">
                        <div className="flex flex-col md:flex-row md:space-x-6">
                            <div className="md:w-1/2 mb-6 md:mb-0">
                                <ProductImages mainImage={mainImage} images={images} title={selectedTranslation.title} />
                            </div>
                            <div className="md:w-1/2">
                                <ProductInfo product={product} selectedLanguage={selectedLanguage} />
                            </div>
                        </div>

                        {variants.length > 0 && <VariantList variants={variants} selectedLanguage={selectedLanguage} />}
                    </div>
                </div>
            </div>
        </div>


    );
};

export default ProductDetails;
