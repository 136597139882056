
import React from 'react';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAuth } from '@context/AuthContext';
import { Image } from 'primereact/image';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useFormik } from 'formik';
import { loginIV } from '@utils/initValues/LoginIV';
import { loginVS } from '@schemas/LoginSchema';
import { Toast } from 'primereact/toast';


export default function Login() {

    let logo = require('@resources/logo.png')
    let { login, toast } = useAuth()
    let [authLoading, setAuthLoading] = React.useState<boolean>(false)


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: loginIV,
        validationSchema: loginVS,
        onSubmit: (values) => {
            console.log({ values });
            handleLogin()
        }
    })

    // let [rememberMe, setRememberMe] = React.useState(false);
    let handleLogin = () => {
        setAuthLoading(true)
        login(values).then(res => {
            setAuthLoading(false)
        }).catch(err => {
            setAuthLoading(false)

            throw err
        })
    }

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isValid,
        setValues } = formik

    //this will be used later to handle theme switching (dark mode)
    // let {changeTheme} = React.useContext(PrimeReactContext);


    return (
        <>
            <Toast ref={toast} />
            <div className="h-screen flex flex-row justify-content-center align-items-center">
                <div className="flex flex-column md:flex-row w-1/2 h-2/3 md:h-1/2 ">
                    <div className="w-full md:w-1/2 flex flex-column align-items-center justify-content-center gap-3 py-5 ">
                        <Image src={logo} alt="logo" width="w-1/2" />
                    </div>
                    <div className="w-full md:w-2">
                        <Divider layout="vertical" className="hidden md:flex">
                        </Divider>
                        <Divider layout="horizontal" className="flex md:hidden" align="center">
                        </Divider>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-column align-items-center justify-content-center gap-3 py-5 ">
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Username</label>
                            <InputText id="username" type="text" className="w-12rem" onChange={handleChange('email')} value={values.email} onBlur={handleBlur('email')} />
                            {(errors.email && touched.email) && <small className="p-error">{errors.email}</small>}
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
                            <label className="w-6rem">Password</label>
                            <InputText id="password" type="password" className="w-12rem" onChange={handleChange('password')} value={values.password} onBlur={handleBlur('password')} />
                            {(errors.password && touched.password) && <small className="p-error">{errors.password}</small>}
                        </div>
                        {authLoading ? <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" />
                            : <Button label="Login" icon="pi pi-user" className={`w-10rem mx-auto ${!isValid && "opacity-50"} `} disabled={!isValid} type="submit" onClick={() => handleSubmit()}></Button>}
                    </div>
                </div>
            </div>
        </>
    )
}
