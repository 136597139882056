import React from "react";
import { Button } from "primereact/button";

interface VariantAttributesButtonProps {
    onClick: () => void;
}

const VariantAttributesButton: React.FC<VariantAttributesButtonProps> = ({ onClick }) => {
    return (
        <Button
            label="Attributes"
            icon="pi pi-info-circle"
            className="p-button-sm"
            onClick={onClick}
        />
    );
};

export default VariantAttributesButton;
