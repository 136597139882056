import { useState, useMemo } from 'react';

interface UsePaginationProps {
    totalItems: number;
    itemsPerPage: number;
    initialPage?: number;
}

const usePagination = ({ totalItems, itemsPerPage, initialPage = 1 }: UsePaginationProps) => {
    const [currentPage, setCurrentPage] = useState(initialPage);

    const totalPages = useMemo(() => {
        return Math.max(1, Math.ceil(totalItems / itemsPerPage));
    }, [totalItems, itemsPerPage]);

    const canPreviousPage = currentPage > 1;
    const canNextPage = currentPage < totalPages;

    const nextPage = () => {
        if (canNextPage) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const previousPage = () => {
        if (canPreviousPage) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const setPage = (page: number) => {
        const pageNumber = Math.max(1, Math.min(page, totalPages));
        setCurrentPage(pageNumber);
    };

    return {
        currentPage,
        totalPages,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPage,
    };
};

export default usePagination;
