import React from "react";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag"; // PrimeReact Tag

interface VariantAttributesModalProps {
    attributes: any[]; // Array of variant attributes
    isVisible: boolean;
    onHide: () => void;
}

const VariantAttributesModal: React.FC<VariantAttributesModalProps> = ({ attributes, isVisible, onHide }) => {
    return (
        <Dialog
            header="Variant Attributes"
            visible={isVisible}
            onHide={onHide}
            style={{ width: '50vw' }} // Slightly wider for better readability
            dismissableMask={true}
            className="rounded-lg shadow-lg bg-gradient-to-r from-gray-50 to-white"
        >
            <div className="p-6">
                {attributes.length > 0 ? (
                    <ul className="space-y-6">
                        {attributes.map((attr: any, index: number) => (
                            <li key={index} className="px-6 py-4 bg-white rounded-xl shadow-md border border-gray-200 hover:shadow-lg transition-shadow duration-200">
                                <div className="flex justify-between items-center mb-4">
                                    {/* Attribute Name with Tag */}
                                    <div className="flex items-center space-x-2">
                                        <i className="pi pi-tag text-blue-500 text-lg"></i> {/* PrimeReact tag icon as plain <i> */}
                                        <Tag value={attr.attribute.name} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full font-medium" />
                                    </div>
                                    {/* Attribute Value */}
                                    <span className="text-gray-700 font-semibold text-md">
                                        {attr.value}
                                    </span>
                                </div>

                                {/* Variant Price Section */}
                                {attr.variantPrice && attr.variantPrice.length > 0 && (
                                    <div className="mt-4">
                                        <span className="block font-semibold text-gray-800 mb-2">Prices:</span>
                                        <ul className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {attr.variantPrice.map((price: any, priceIndex: number) => (
                                                <li
                                                    key={priceIndex}
                                                    className="flex justify-between items-center p-4 rounded-lg bg-green-50 border border-green-200 hover:bg-green-100 transition-colors duration-150"
                                                >
                                                    <div className="flex items-center space-x-2">
                                                        <i className="pi pi-flag text-green-600 text-lg"></i> {/* PrimeReact flag icon as plain <i> */}
                                                        <span>{price.country.name} : </span>
                                                    </div>
                                                    <div className="flex items-center space-x-1 text-green-800 font-medium">
                                                        <i className="pi pi-dollar text-lg"></i> {/* PrimeReact dollar icon as plain <i> */}
                                                        <span>{price.price} {price.country.currency}</span>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500 text-center text-lg mt-4">No Attributes Available</p>
                )}
            </div>
        </Dialog>
    );
};

export default VariantAttributesModal;
