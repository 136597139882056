// VariantItem.tsx
import React from 'react';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider';
import VariantAttributes from './VariantAttributes';
import { Carousel } from 'primereact/carousel';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface VariantItemProps {
    variant: any;
}

const VariantItem: React.FC<VariantItemProps> = ({ variant }) => {
    const carouselImages = variant.variantImages.map((img: any) => ({
        src: img.image,
        alt: 'Variante',
    }));

    const carouselItemTemplate = (item: any) => (
        <div className="carousel-item">
            <Image src={item.src} alt={item.alt} width="100%" className="rounded-md shadow-md" preview />
        </div>
    );

    return (
        <div className="border border-gray-200 px-4 py-4 md:px-6 md:py-6 mb-6 rounded-lg bg-white shadow-md">
            {/* Titre avec tags et disponibilité */}
            <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-4 flex flex-wrap items-center gap-2">
                {variant.variantAttributes.map((attr: any, index: number) => (
                    <Tag key={index} severity="info" value={attr.value} className="mb-2" />
                ))}
                <Tag
                    value={variant.disponibilite ? "Disponible" : "Indisponible"}
                    severity={variant.disponibilite ? "success" : "danger"}
                    className="ml-auto"
                />
            </h3>

            {/* Contenu principal responsive avec flexbox */}
            <div className="flex flex-col md:flex-row md:space-x-6">
                {/* Section image et carousel */}
                <div className="md:w-1/2 mb-4 md:mb-0">
                    <Image
                        src={variant.image}
                        alt={variant.label}
                        width="100%"
                        className="rounded-lg shadow-sm object-cover"
                        preview
                    />
                    {carouselImages.length > 0 && (
                        <div className="mt-4">
                            <Carousel
                                value={carouselImages}
                                numVisible={3}
                                numScroll={1}
                                itemTemplate={carouselItemTemplate}
                                circular
                                autoplayInterval={3000}
                                className="custom-carousel"
                                responsiveOptions={[
                                    { breakpoint: '1024px', numVisible: 2, numScroll: 1 },
                                    { breakpoint: '768px', numVisible: 1, numScroll: 1 }
                                ]}
                            />
                        </div>
                    )}
                </div>

                {/* Informations sur la variante */}
                <div className="md:w-1/2 space-y-4">
                    <div className="flex flex-wrap items-center gap-2">
                        <Tag severity="danger" value={`Stock: ${variant.stock}`} />
                        <Tag severity="info" value={`Réf: ${variant.reference}`} />
                        <Tag severity="success" value={`Code-barres: ${variant.barcode}`} />
                    </div>
                    <Divider />

                    {/* Accordéon pour les détails */}
                    <Accordion multiple>
                        <AccordionTab header="Description">
                            <p className="text-gray-700 text-sm md:text-base">{variant.description}</p>
                        </AccordionTab>
                        <AccordionTab header="Comment utiliser">
                            <p className="text-gray-700 text-sm md:text-base">{variant.howToUse}</p>
                        </AccordionTab>
                        <AccordionTab header="Ingrédients">
                            <p className="text-gray-700 text-sm md:text-base">{variant.ingredients}</p>
                        </AccordionTab>
                    </Accordion>

                    {/* Attributs de la variante */}
                    {variant.variantAttributes.length > 0 && (
                        <VariantAttributes variantAttributes={variant.variantAttributes} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default VariantItem;
