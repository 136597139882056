import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

const LoadingData: React.FC = () => {
    return (
        <div className="text-center">
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" />
        </div>

    );
};

export default LoadingData;
