// CountriesList.tsx
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import LoadingData from '@components/Loading/LoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { apiUrl } from '@helpers/Helpers';
import { useQuery } from '@tanstack/react-query';
import usePagination from '@hooks/usePagination';
import Pagination from '@components/pagination/Pagination';
import debounce from 'lodash.debounce';// Assurez-vous d'avoir une interface Country
import { Country } from '@utils/interfaces/Country';
import CountryFlag from '@components/Global/CountryFlag';

interface FilterCountries {
    name: string;
    code: string;
    item_page: number;
}

const CountriesList: React.FC = () => {
    const navigate = useNavigate();
    const { apiRequest } = useApiRequest();

    const [filters, setFilters] = useState<FilterCountries>({
        name: '',
        code: '',
        item_page: 10,
    });
    const defaultFilters: FilterCountries = {
        name: '',
        code: '',
        item_page: 10,
    };

    const [totalItems, setTotalItems] = useState(0);

    const {
        currentPage,
        totalPages,
        setPage,
    } = usePagination({
        totalItems,
        itemsPerPage: filters.item_page,
    });

    const params = useMemo(() => {
        return {
            page: currentPage,
            'items_per_page': filters.item_page,
            ...(filters.name && { name: filters.name }),
            ...(filters.code && { code: filters.code }),
        };
    }, [filters, currentPage]);

    const fetchCountries = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/countries.jsonld`,
            method: 'GET',
            params: params,
            headers: {
                Accept: 'application/ld+json',
            },
        });

        if (response.data && response.data['hydra:totalItems']) {
            setTotalItems(response.data['hydra:totalItems']);
        } else {
            setTotalItems(0);
        }

        return response.data ? response.data['hydra:member'] : [];
    }, [apiRequest, params]);

    const { data: countries = [], isLoading, error, refetch } = useQuery({
        queryKey: ['countries', params],
        queryFn: fetchCountries
    });

    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    [key]: value,
                }));
                setPage(1);
            }, 500),
        [setFilters, setPage]
    );

    const resetFiltersAndRefetch = () => {
        setFilters(defaultFilters); // Réinitialise les filtres
        setPage(1); // Remet à la première page
        refetch();  // Relance la requête sans filtres
    };

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) {
        return (
            <div className="text-center mt-4">
                <p className="text-red-500">Failed to load countries. Please try again.</p>
                <Button label="Retry" onClick={() => resetFiltersAndRefetch()} />
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto my-4 px-2 sm:px-4">
            <Card className="shadow-lg border-round-lg">
                <div className="bg-green-100 border-2 border-green-200 rounded-lg p-3 flex flex-col sm:flex-row justify-between items-center">
                    <h2 className="text-lg font-semibold text-green-700 mb-2 sm:mb-0">
                        Countries List
                    </h2>
                    <Button
                        label="Create New Country"
                        icon="pi pi-plus"
                        className="p-button-sm p-button-outlined"
                        onClick={() => navigate('/dashboard/setting/countries/add')}
                    />
                </div>

                {/* Filters */}
                <div className="px-4 py-3">
                    <div className="flex flex-row gap-3">
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="name"
                                defaultValue={filters.name}
                                onChange={(event) =>
                                    debouncedHandleFilterChange('name', event.target.value)
                                }
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="code"
                                name="code"
                                placeholder="code"
                                defaultValue={filters.code}
                                onChange={(event) =>
                                    debouncedHandleFilterChange('code', event.target.value)
                                }
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="number"
                                id="item_page"
                                name="item_page"
                                placeholder="Items per page"
                                defaultValue={filters.item_page}
                                onChange={(event) =>
                                    debouncedHandleFilterChange('item_page', event.target.value)
                                }
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className="px-4 py-1 overflow-x-auto">
                    {isLoading ? (
                        <LoadingData />
                    ) : (
                        <>
                            <table className="min-w-full bg-white rounded-lg shadow-sm text-sm table-auto">
                                <thead>
                                    <tr className="bg-green-400 text-white uppercase text-xs text-center leading-tight">
                                        <th className="py-3 px-6 text-left border-b">Icon</th>
                                        <th className="py-3 px-6 text-left border-b">Name</th>
                                        <th className="py-3 px-6 text-left border-b">Code</th>
                                        <th className="py-3 px-6 text-left border-b">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {countries.length > 0 ? (
                                        countries.map((country: Country) => (
                                            <tr
                                                key={country.id}
                                                className="hover:bg-green-50 transition-colors ease-in-out border-b text-sm"
                                            >
                                                <td className="py-3 px-6 text-left font-semibold"><CountryFlag countryCode={country.code} size={42} /></td>
                                                <td className="py-3 px-6 text-left font-semibold">
                                                    {country.name}
                                                </td>
                                                <td className="py-3 px-6 text-left">{country.code}</td>
                                                <td className="py-3 px-6 text-left">
                                                    <div className="flex space-x-2">
                                                        <Link to={`edit/${country.id}`}>
                                                            <Button
                                                                icon="pi pi-pencil"
                                                                className="p-button-rounded p-button-info p-button-sm"
                                                                tooltip="Edit"
                                                                tooltipOptions={{ position: 'top' }}
                                                            />
                                                        </Link>
                                                        <Link to={`delete/${country.id}`}>
                                                            <Button
                                                                icon="pi pi-trash"
                                                                className="p-button-rounded p-button-danger p-button-sm"
                                                                tooltip="Delete"
                                                                tooltipOptions={{ position: 'top' }}
                                                            />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3} className="py-3 px-6 text-center text-gray-500">
                                                No countries found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={setPage}
                            />
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default CountriesList;
