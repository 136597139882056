
import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";


export const fetchProduct = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/products/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur Product Not Found !!');
        throw new Error('Erreur lors de la récupération de la produit');
    }
};
