import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "@context/AuthContext";
import SideBar from "@components/SideBar/SideBar";
import Loading from "@components/Loading/Loading";
import Header from "@components/Home/Header";
import AppSidebar from "@components/SideBar/SideBar";
import { useState } from "react";

interface ProtectedRouteProps {
    roles?: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles = [] }) => {
    let { userToken, roles: userRoles, loading } = useAuth();
    let outlet = useOutlet();

    const [visible, setVisible] = useState<boolean>(false);

    if (loading) {
        // Afficher le composant de chargement avec Tailwind CSS
        return <Loading />;
    }

    console.log("les roles de user : ", userRoles);
    if (!userToken) {
        // L'utilisateur n'est pas authentifié
        return <Navigate to="/" />;
    }

    if (roles.length > 0 && !roles.some(role => userRoles.includes(role))) {
        // L'utilisateur n'a pas les rôles requis pour accéder à cette route
        return <Navigate to="/not-authorized" />;
    }



    // L'utilisateur est authentifié et a les rôles requis
    return (
        <div className=" bg-gray-100" >
            <div className="shadow-lg">
                <Header  setVisible={setVisible}/>
                <AppSidebar visible={visible} setVisible={setVisible} />
            </div>
            <div className="min-h-screen container mx-auto  ">
                {outlet}
            </div>
        </div>
    );
};
