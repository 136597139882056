import React, { useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Card } from 'primereact/card';
import { Button as PrimeButton } from 'primereact/button';
import { CategoriesAddSchema } from '../../utils/schemas/CategoriesSchema';
import { CategoriesIV } from '@utils/initValues/CategoriesIV';
import ErrorLoadingData from '@components/Errors/ErrorLoadingData';
import useApiRequest from '@mbs-dev/api-request';
import { getCategory, updateCategory } from '@utils/api/category';
import CategoryTranslationFields from '@components/categories/add/CategoryTranslationFields';
import FileUploadWithPreview from '@components/Form/FileUploadWithPreview';
import LoadingData from '@components/Loading/LoadingData';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from '@mbs-dev/react-helpers';
import { fetchLanguages } from '@utils/api/Lang';
import { appUrl, bannerImgUrl, categoryImgUrl } from '@helpers/Helpers';
import { Language } from '@utils/interfaces/language';



interface CategoryFormData {
    image: File | null;
    banner: File | null;
    categoryTranslates: {
        title: string;
        slug: string;
        language: string;
        description: string;
    }[];
}

const CategoriesEdit: React.FC = () => {
    const { apiRequest } = useApiRequest();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id: string }>(); // Get the category ID from the URL params

    // Fetch category data to edit
    const { data: categoryData, isLoading: isCategoryLoading, error: categoryError } = useQuery({
        queryKey: ['category', id],
        queryFn: () => getCategory(apiRequest, id), // Function to fetch category data by ID
    });

    const { data: languages, isLoading: isLanguagesLoading, error: languagesError } = useQuery<Language[]>({
        queryKey: ['languages'],
        queryFn: () => fetchLanguages(apiRequest),
    });

    // Mutation to update the category
    const mutation = useMutation({
        mutationFn: (data: FormData) => updateCategory(apiRequest, id, data), // Update category function
        onSuccess: () => {
            navigate('/dashboard/categories');
            queryClient.invalidateQueries({
                queryKey: ['categories'],
            });
        },
        onError: () => {
            notify.errorNotify('Erreur lors de la mise à jour de la catégorie');
        },
    });

    const formik = useFormik<CategoryFormData>({
        initialValues: CategoriesIV, // Initialize with empty values
        validationSchema: CategoriesAddSchema,
        onSubmit: (values) => {
            console.log(values);

            // const formData = new FormData();

            // // Add image and banner if they exist
            // if (values.image) {
            //     formData.append('image', values.image);
            // }
            // if (values.banner) {
            //     formData.append('banner', values.banner);
            // }

            // // Add translations to formData
            // values.categoryTranslates.forEach((translate, index) => {
            //     formData.append(`categoryTranslates[${index}][title]`, translate.title);
            //     formData.append(`categoryTranslates[${index}][slug]`, translate.slug);
            //     formData.append(`categoryTranslates[${index}][language]`, translate.language);
            //     formData.append(`categoryTranslates[${index}][description]`, translate.description);
            // });

            // mutation.mutate(formData);
        },
        enableReinitialize: true, // Allow form values to reinitialize when data is fetched
    });

    useEffect(() => {
        if (categoryData) {
            console.log("data from api : " , categoryData);
            
            formik.setValues({
                image: categoryData.image,
                banner: categoryData.banner,
                categoryTranslates: categoryData.categoryTranslates.map((translate: any) => ({
                    // language: {
                    //     id: translate.language.id,  // Use the correct ID for language
                    //     name: translate.language.name,  // Populate name for display
                    //     code: translate.language.code,  // Populate code if needed
                    // },
                    language : translate.language.id , 
                    title: translate.title,
                    slug: translate.slug,
                    description: translate.description,
                })),
            });
        }
    }, [categoryData]);
    

    useEffect(() => {
        console.log("values : " , formik.values);
        
    }, [formik.values]);

    // Handle file selection for image and banner
    const handleFileSelect = (setFieldValue: (field: string, value: any) => void, fieldName: string) => (file: File | null) => {
        setFieldValue(fieldName, file);
    };

    if (categoryError || languagesError) return <ErrorLoadingData entity="category or languages" />;

    return (
        <FormikProvider value={formik}>
            <div className="max-w-4xl mx-auto my-4">
                <Card className="shadow-lg border-round-lg">
                    <div className="bg-green-200 py-2 px-4 text-center rounded-t-lg">
                        <h2 className="text-xl font-semibold">Edit Category</h2>
                    </div>

                    {isCategoryLoading || isLanguagesLoading ? (
                        <LoadingData />
                    ) : (
                        <form onSubmit={formik.handleSubmit} className="p-4 space-y-4 text-sm">
                            {/* File Uploads */}
                            <div className="flex flex-col gap-4 md:flex-row">
                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Category Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Category Image"
                                        onFileSelect={formik.setFieldValue.bind(null, 'image')}
                                        initialPreview={categoryData?.image ? `${categoryImgUrl}/${categoryData.image}` : null} // Use Formik initial value
                                    />
                                    {formik.touched.image && formik.errors.image && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.image}</p>
                                    )}
                                </div>

                                <div className="md:w-1/2 w-full">
                                    <label className="block text-sm font-medium mb-2">Banner Image :</label>
                                    <FileUploadWithPreview
                                        label="Choose Banner Image"
                                        onFileSelect={formik.setFieldValue.bind(null, 'banner')}
                                        initialPreview={categoryData?.banner ? `${bannerImgUrl}/${categoryData.banner}` : null} // Use Formik initial value
                                    />
                                    {formik.touched.banner && formik.errors.banner && (
                                        <p className="text-red-500 text-xs mt-1">{formik.errors.banner}</p>
                                    )}
                                </div>
                            </div>

                            {/* Translations */}
                            <div className="mt-6">
                                <CategoryTranslationFields
                                    formik={formik}
                                    languages={languages || []}
                                />
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-center">
                                <PrimeButton
                                    label="Update Category"
                                    icon="pi pi-check"
                                    className="p-button-success text-xs"
                                    type="submit"
                                />
                            </div>
                        </form>
                    )}
                </Card>
            </div>
        </FormikProvider>
    );
};

export default CategoriesEdit;
