export const variantColumns = [
    {
        header: "ID",
        accessorKey: "id",
    },
    {
        header: "Label",
        accessorKey: "label",
    },
    {
        header: "Stock",
        accessorKey: "stock",
    },
    {
        header: "Barcode",
        accessorKey: "barcode",
    },
    
];
