// GlobalSearch.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button'; // Importer le composant Button de PrimeReact

interface Page {
    name: string;
    path: string;
}

const pages = [
    { name: "Home", path: "/dashboard/home" },
    { name: "Profile", path: "/dashboard/profile" },
    { name: "Category List", path: "/dashboard/categories" },
    { name: "Add Category", path: "/dashboard/categories/add" },
    { name: "Product List", path: "/dashboard/products" },
    { name: "User List", path: "/dashboard/users" },
    { name: "Add User", path: "/dashboard/users/add" },
    { name: "Languages List", path: "/dashboard/setting/languages" },
    { name: "Add Language", path: "/dashboard/setting/languages/add" },
    { name: "Countries List", path: "/dashboard/setting/countries" },
    { name: "Add Countries", path: "/dashboard/setting/countries/add" },
];

const GlobalSearch: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredPages, setFilteredPages] = useState<Page[]>(pages);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    // Activation du focus avec Ctrl + K ou clic sur l'icône
    const activateSearch = () => {
        setIsFocused(true);
        setSearchQuery("");
        setTimeout(() => searchInputRef.current?.focus(), 0);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
                event.preventDefault();
                activateSearch();
            } else if (event.key === 'Escape') {
                setIsFocused(false);
            } else if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => (prevIndex + 1) % filteredPages.length);
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => 
                    (prevIndex - 1 + filteredPages.length) % filteredPages.length
                );
            } else if (event.key === 'Enter' && filteredPages.length > 0) {
                handleNavigation(filteredPages[selectedIndex].path);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [filteredPages, selectedIndex]);

    // Filtrage de la liste des pages
    useEffect(() => {
        if (searchQuery === "") {
            setFilteredPages(pages);
            setSelectedIndex(0);
        } else {
            const newFilteredPages = pages.filter((page) =>
                page.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredPages(newFilteredPages);
            setSelectedIndex(0);
        }
    }, [searchQuery]);

    const handleNavigation = (path: string) => {
        navigate(path);
        setSearchQuery("");
        setIsFocused(false);
    };

    return (
        <>
            {/* Icône de recherche et raccourci Ctrl + K avec responsive */}
            <div className="fixed bottom-5 right-5 z-50 flex items-center gap-2 lg:flex-row flex-col">
                <Button
                    icon="pi pi-search"
                    className="p-button-rounded p-button-success lg:w-12 w-10 h-10 lg:h-12" // Taille réduite sur petits écrans
                    onClick={activateSearch}
                    aria-label="Search"
                />
                <span className="bg-gray-200 text-gray-700 text-xs lg:text-sm px-2 py-1 rounded-lg shadow-sm">
                    Ctrl + K
                </span>
            </div>

            {isFocused && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-40 z-40 flex items-center justify-center"
                    onClick={() => setIsFocused(false)}
                >
                    <div
                        className="bg-white w-full max-w-lg p-5 rounded-xl shadow-lg relative z-50 transition duration-300 ease-in-out transform hover:scale-105"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Input de recherche */}
                        <div className="relative mb-4">
                            <input
                                type="text"
                                ref={searchInputRef}
                                className="w-full px-5 py-3 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-4 focus:ring-green-300 transition duration-150 ease-in-out"
                                placeholder="Rechercher une page..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-100 text-gray-600 text-xs px-3 py-1 rounded-full shadow-sm">
                                Ctrl + K
                            </div>
                        </div>

                        {/* Liste de résultats */}
                        {searchQuery && (
                            <div className="bg-white rounded-xl shadow-md max-h-64 overflow-y-auto border border-gray-200">
                                {filteredPages.length > 0 ? (
                                    <ul className="divide-y divide-gray-200">
                                        {filteredPages.map((page, index) => (
                                            <li
                                                key={page.path}
                                                onClick={() => handleNavigation(page.path)}
                                                className={`cursor-pointer px-5 py-3 transition duration-150 ease-in-out text-gray-700 ${
                                                    index === selectedIndex
                                                        ? 'bg-green-100 text-green-800 font-semibold'
                                                        : 'hover:bg-gray-100'
                                                }`}
                                            >
                                                {page.name}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="p-4 text-sm text-gray-500 text-center">
                                        Aucun résultat pour "{searchQuery}"
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default GlobalSearch;
