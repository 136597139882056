import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { columns } from "@components/categories/columns";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import LoadingTable from "@components/Loading/LoadingTable";
import ErrorLoadingData from "@components/Errors/ErrorLoadingData";
import debounce from "lodash.debounce";
import usePagination from "@hooks/usePagination";
import Pagination from "@components/pagination/Pagination";
import useApiRequest from "@mbs-dev/api-request";
import { apiUrl } from "@helpers/Helpers";

// Filtres pour les catégories
interface FilterCategories {
    name: string;
    item_page: number;
    description:string ;
}

const CategoriesList: React.FC = () => {
    const navigate = useNavigate();
    const {apiRequest} = useApiRequest() ;

    // États pour les filtres
    const [filters, setFilters] = useState<FilterCategories>({
        name: '',
        description:"" ,
        item_page: 10,
    });

    // État pour le nombre total d'éléments
    const [totalItems, setTotalItems] = useState(0);

    // Système de pagination
    const {
        currentPage,totalPages,setPage,} = usePagination({ totalItems,itemsPerPage: filters.item_page});

    // Paramètres pour les filtres et la pagination
    const params = useMemo(() => {
        return {
            page: currentPage,
            'items_per_page': filters.item_page,
            ...(filters.name && { "categoryTranslates.title": filters.name }),
            ...(filters.description && { "categoryTranslates.description": filters.description }),
        };
    }, [filters, currentPage]);




    const fetchFilteredCategories = useCallback(async () => {
        const response = await apiRequest({
            route: `${apiUrl}/categories.jsonld`,
            method: 'GET',
            params: params,
            headers: {
                Accept: 'application/ld+json',
            },
        });

        if (response.data && response.data['hydra:totalItems']) {
            setTotalItems(response.data['hydra:totalItems']);
        } else {
            setTotalItems(0);  
        }

        return response.data ? response.data['hydra:member'] : [];
    }, [apiRequest, params]);

    const { data, isLoading, error } = useQuery({
        queryKey: ['categories', params],
        queryFn: fetchFilteredCategories
    });

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    // Debounce pour la gestion des filtres
    const debouncedHandleFilterChange = useMemo(
        () =>
            debounce((key: string, value: any) => {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    [key]: key === 'item_page' ? parseInt(value, 10) : value,
                }));
                setPage(1); // Réinitialiser à la première page après un changement de filtre
            }, 500),
        [setFilters, setPage]
    );

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, [debouncedHandleFilterChange]);

    if (error) return <ErrorLoadingData entity="categories" />;

    const header = () => (
        <div className="flex justify-between items-center p-3 bg-green-100 border-2 border-green-200 rounded-lg">
            <h2 className="text-lg font-semibold text-green-700">Categories List</h2>
            <Button
                label="Add Category"
                icon="pi pi-plus"
                className="p-button-sm p-button-outlined"
                onClick={() => navigate("/dashboard/categories/add")}
            />
        </div>
    );

    return (
        <div className="pt-3">
            <Panel headerTemplate={header} className="rounded-lg p-2">
                {/* Filtres */}
                <div className="px-4 py-3">
                    <div className="flex flex-row gap-3">
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Filter by name"
                                defaultValue={filters.name}
                                onChange={(event) => debouncedHandleFilterChange('name', event.target.value)}
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="text"
                                id="description"
                                name="description"
                                placeholder="description"
                                defaultValue={filters.name}
                                onChange={(event) => debouncedHandleFilterChange('description', event.target.value)}
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                        <div className="w-1/2 md:w-1/4">
                            <input
                                type="number"
                                id="item_page"
                                name="item_page"
                                placeholder="Items per page"
                                defaultValue={filters.item_page}
                                onChange={(event) => debouncedHandleFilterChange('item_page', event.target.value)}
                                className="p-inputtext w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-300"
                            />
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-lg shadow-sm text-sm">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-green-400 text-white uppercase text-xs text-center leading-tight">
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className="py-2 px-4 text-left cursor-pointer hover:bg-green-500"
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {!isLoading ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id} className="border-b hover:bg-green-50 transition ease-in-out text-sm">
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} className="py-2 px-4 text-left">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <LoadingTable />
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
            </Panel>
        </div>
    );
};

export default CategoriesList;
