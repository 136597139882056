import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

interface Translation {
    id: number;
    title: string;
    slug: string;
    description: string;
    language: {
        id: number;
        name: string;
        code: string;
    };
}

interface TranslationCellProps {
    translations: Translation[];
}

const TranslationCell: React.FC<TranslationCellProps> = ({ translations }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleOpenDialog = () => setShowDialog(true);

    const handleCloseDialog = () => setShowDialog(false);

    return (
        <>
            <Button
                label="View Translations"
                icon="pi pi-eye"
                className="p-button-sm p-button-info"
                onClick={handleOpenDialog}
            />

            <Dialog
                visible={showDialog}
                onHide={handleCloseDialog}
                header="Translations"
                style={{ width: '90vw', maxWidth: '800px' }}
                dismissableMask={true}  // Close modal on clicking outside
                footer={
                    <Button
                        label="Close"
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={handleCloseDialog}
                    />
                }
            >
                {translations.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">  {/* Responsive grid */}
                        {translations.map((translation, index) => (
                            <div
                                key={translation.id}
                                className="mt-2 px-4 py-3 rounded-xl shadow-lg bg-white relative transition-all hover:shadow-2xl border-t-4"
                                style={{
                                    borderColor: index % 2 === 0 ? '#42A5F5' : '#66BB6A',
                                }}
                            >
                                <Tag
                                    value={`${translation.language.name} (${translation.language.code})`}
                                    className="absolute top-2 right-2 bg-blue-100 text-blue-800"
                                />
                                <div className="mb-1">
                                    <h4 className="text-lg font-semibold mb-2 text-gray-700">{translation.title}</h4>
                                    <p className="text-sm text-gray-500"><strong>Slug:</strong> {translation.slug}</p>
                                </div>
                                <div className="text-sm text-gray-600">
                                    <p className="text-gray-500 whitespace-pre-line">
                                        <strong>Description:</strong>{' '}
                                        {translation.description || 'No description provided'}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No Translations Available</p>
                )}
            </Dialog>
        </>
    );
};

export default TranslationCell;
