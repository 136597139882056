import React, { useState, useEffect } from 'react';

interface FileUploadWithPreviewProps {
    label: string;
    onFileSelect: (file: File | null) => void;
    maxFileSizeMB?: number;
    initialPreview?: string | null; // To accept the initial preview from Formik
}

const FileUploadWithPreview: React.FC<FileUploadWithPreviewProps> = ({
    label,
    onFileSelect,
    maxFileSizeMB = 5,
    initialPreview = null,
}) => {
    const [selectedFilePreview, setSelectedFilePreview] = useState<string | null>(initialPreview);
    const [selectedFileSize, setSelectedFileSize] = useState<string | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);

    useEffect(() => {
        if (initialPreview) {
            setSelectedFilePreview(initialPreview);
        }
    }, [initialPreview]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            const fileSizeMB = file.size / (1024 * 1024); 

            if (fileSizeMB > maxFileSizeMB) {
                setFileError(`File size exceeds the limit of ${maxFileSizeMB} MB.`);
                setSelectedFilePreview(null);
                setSelectedFileSize(null);
                onFileSelect(null);
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const preview = reader.result as string;
                setSelectedFilePreview(preview);
                setSelectedFileSize(calculateFileSize(file));
                setFileError(null);
                onFileSelect(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setSelectedFilePreview(null);
        setSelectedFileSize(null);
        setFileError(null);
        onFileSelect(null);
    };

    const calculateFileSize = (file: File): string => {
        const sizeInKB = (file.size / 1024).toFixed(2);
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        return sizeInMB > "1" ? `${sizeInMB} MB` : `${sizeInKB} KB`;
    };

    return (
        <div className="flex flex-col items-start space-y-2">
            <label className="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-600 transition-colors duration-300">
                {label}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                    aria-label={`Upload ${label}`}
                />
            </label>

            {fileError && <p className="text-red-500 text-sm">{fileError}</p>}

            {selectedFilePreview && (
                <div className="relative mt-4">
                    <img
                        src={selectedFilePreview}
                        alt="Selected preview"
                        className="w-32 h-32 object-cover rounded-md shadow-md"
                    />

                    <button
                        onClick={handleRemoveImage}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-300"
                        aria-label="Remove selected image"
                    >
                        &#10006;
                    </button>

                    {selectedFileSize && (
                        <span className="absolute bottom-1 right-1 bg-gray-800 text-white text-xs px-2 py-1 rounded-md shadow-lg">
                            {selectedFileSize}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default FileUploadWithPreview;
