// ProductInfo.tsx
import React from 'react';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CountryFlag from '@components/Global/CountryFlag';

interface ProductInfoProps {
    product: any;
    selectedLanguage: any;
}

const formatCurrency = (amount: number, currencyCode: string): string => {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};

const ProductInfo: React.FC<ProductInfoProps> = ({ product, selectedLanguage }) => {
    const translation = product.productTranslates.find((pt: any) => pt.language.code === selectedLanguage.code) || product.productTranslates[0];

    return (
        <div className="border border-gray-200 p-4 rounded-lg bg-white shadow-lg md:px-6 md:py-4 lg:px-8 lg:py-6">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-gray-700 mb-4">
                Détails du produit
            </h2>

            <div className="flex flex-wrap items-center gap-2 mb-4">
                <Tag
                    severity={product.disponibilite ? 'success' : 'danger'}
                    value={product.disponibilite ? 'Disponible' : 'Indisponible'}
                    className="mb-2 md:mb-0"
                />
                <Tag severity="info" value={`Stock: ${product.stock}`} className="mb-2 md:mb-0" />
            </div>

            <Accordion multiple className="mt-4">
                <AccordionTab header="Description">
                    <p className="text-gray-600 text-sm md:text-base">{translation.description}</p>
                </AccordionTab>
                <AccordionTab header="Comment utiliser">
                    <p className="text-gray-600 text-sm md:text-base">{translation.howToUse}</p>
                </AccordionTab>
                <AccordionTab header="Ingrédients">
                    <p className="text-gray-600 text-sm md:text-base">{translation.ingredients}</p>
                </AccordionTab>
            </Accordion>

            <Divider className="my-4" />

            <div className="bg-gray-50 p-3 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium text-gray-700 mb-3">Prix :</h3>
                <div className="space-y-2">
                    {product.productPrice.map((price: any) => (
                        <div key={price.id} className="flex items-center text-sm md:text-base gap-2">
                            <CountryFlag countryCode={price.country.code} size={18} />
                            <span className="text-gray-600">{price.country.name}</span>
                            <span className="ml-auto px-3 py-1 rounded-full bg-green-100 text-green-800 font-medium shadow-sm">
                                {formatCurrency(price.price, price.country.currency)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;
