// VariantList.tsx
import React from 'react';
import VariantItem from './VariantItem';

interface VariantListProps {
    variants: any[];
    selectedLanguage: any;
}

const VariantList: React.FC<VariantListProps> = ({ variants, selectedLanguage }) => {
    return (
        <div className="mt-10">
            {/* Titre avec taille de police adaptative */}
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-green-600 mb-6">
                Variantes
            </h2>

            {/* Disposition responsive en grille */}
            <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {variants.map((variant: any) => (
                    <VariantItem key={variant.id} variant={variant}  />
                ))}
            </div>
        </div>
    );
};

export default VariantList;
