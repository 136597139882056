import { Category } from "@utils/interfaces/category";
import { API } from "./API_INSTANCE"
import { apiUrl } from "@helpers/Helpers";
import { notify } from "@mbs-dev/react-helpers";


export const fetchCategories = async (): Promise<Category[]> => {
    const response = await API.get<Category[]>('/categories.json');
    return response.data;
};


export const addCategory = async (apiRequest: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/categories`,
            method: 'POST',
            data: data,
            // requiresAuth: true,
            // token: `${localStorage.getItem('userToken')}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        notify.successNotify("Category create successfully");
    } catch (error) {
        notify.errorNotify("Erreur lors de la création de la catégorie")
    }
};

export const deleteCategories= async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/categories/${id}.json`,
            method: 'DELETE',
        });
        notify.successNotify('Langue supprimée avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la suppression de la langue');
        throw new Error('Erreur lors de la suppression de la langue');
    }
};




export const getCategory = async (apiRequest: any, id: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/categories/${id}.json`,
            method: 'GET',
        });
        return response.data;
    } catch (error) {
        notify.errorNotify('Erreur category Not Found !!');
        throw new Error('Erreur lors de la récupération de la category');
    }
};


export const updateCategory = async (apiRequest: any, id: any, data: any) => {
    try {
        const response = await apiRequest({
            route: `${apiUrl}/categories/${id}`,
            method: 'PUT',
            data: data,
        });

        notify.successNotify('category mise à jour avec succès');
    } catch (error) {
        notify.errorNotify('Erreur lors de la mise à jour de la category');
    }
};